import { SYMBOL_ALL, TABLE_TRANSACTION, TABLE_USER } from 'config/constants'
import { db } from 'config/db/firebase'
import { UserModel } from 'config/types/userType'
import { addDoc, collection, doc, getDocs, increment, query, setDoc, Timestamp, updateDoc, where } from 'firebase/firestore'
import { decimals, getMyIp, hideLoading, loading, toUsd } from 'utils/helpers'

export const createNewUserDB = async (uid: string, data) => {
  await setDoc(doc(db, TABLE_USER, uid), data).catch(error => {
    console.log('Error set doc user', error)
  })
  return true
}

export const updateUserDB = async (docId: string, data) => {
  await setDoc(doc(db, TABLE_USER, docId), data, { merge: true }).catch(error => {
    console.log('Error set doc user', error)
  })
  return true
}

export const updateLoginTimeIp = async (uid: string) => {
  const myIp = await getMyIp()
  await updateDoc(doc(db, TABLE_USER, uid), {
    timeLogin: Timestamp.now(),
    ipLogin: myIp
  }).catch(error => {
    console.log('Error update login time ip user', error)
  })
  return true
}

export const addDocToCollDynamic = async (coll: string, data) => {
  await addDoc(collection(db, coll), data)
    .then(value => value.id)
    .catch(error => {
      console.log('Error add doc ips', error)
    })
  return true
}

export const updateAnyField = async (coll: string, docId: string, data) => {
  await setDoc(doc(db, coll, docId), data, { merge: true }).catch(error => {
    console.log(`Failed to update - ${coll} : ${error}`)
  })
  return true
}

export const saveAnyFieldValue = async (coll: string, docId: string, field: string, amount: number) => {
  await setDoc(doc(db, coll, docId), { [field]: increment(amount) }, { merge: true }).catch(error => {
    console.log(`Failed to update - ${field} : ${error}`)
  })
  return amount
}

export const addTransactions = async (
  amount: number,
  symbol?: string,
  rate?: number,
  type?: string,
  note?: string,
  userDB?: UserModel,
  fee?: number,
  status?: string,
  wallet?: string,
  uOtherUid?: string,
  uOtherName?: string
) => {
  await addDoc(collection(db, TABLE_TRANSACTION), {
    amount: amount,
    fee: fee ?? 0,
    rate: rate ?? 0,
    status: status ?? 'done',
    type: type,
    note: note ?? '',
    symbol: symbol ?? SYMBOL_ALL,
    wallet: wallet ?? 'wUsd',
    uUid: userDB!.uid,
    uName: userDB!.name,
    uOtherUid: uOtherUid ?? '',
    uOtherName: uOtherName ?? '',
    timeMilis: Timestamp.now().toMillis(),
    t: userDB!.role == 'dev' || userDB!.role == 'T' ? true : false
  }).catch(error => {
    console.log('Error set doc transactions', error)
  })
}

export const findFirstUser = async (by: string) => {
  // const byOk = splitRefUrl(by)
  const refCodeLow = by.trim().toLowerCase()
  if (by != '') {
    let userResult
    const q = query(collection(db, TABLE_USER), where('refCode', '==', refCodeLow))
    await getDocs(q).then(user => {
      if (user.docs.length > 0) {
        userResult = { ...user.docs[0].data(), uid: user.docs[0].id } as UserModel
      }
    })
    return userResult
  }
  return null
}

export const transactionFunc = async (type: string, fee: number, amount: number, wallet: string, symbol: string, address: string, userDB: UserModel, rate: number) => {
  loading('User Update...')
  if (type === 'Withdraw') {
    await saveAnyFieldValue(TABLE_USER, userDB.uid!, wallet, -amount)
    await addTransactions(-amount, symbol, rate, type, '', userDB, fee ?? 0, 'pending', wallet, address, address)
  } else if (type === 'Swap') {
    const note = `${type}: ${decimals(amount)} ${symbol} to: ${decimals(toUsd(amount, fee, rate))} ${SYMBOL_ALL}`
    await saveAnyFieldValue(TABLE_USER, userDB.uid!, wallet, -amount)
    await addTransactions(-amount, symbol, rate, type, note, userDB, fee ?? 0, 'pending', wallet, address, address)
  }
  hideLoading()
}

// export const findUserAnyFieldToListStream = async (whereField: string, whereValue: string) => {
//   if (whereField && whereValue) {
//     let userResult
//     const q = query(collection(db, TABLE_USER), where(whereField, '==', whereValue))
//     await getDocs(q).then(user => {
//       if (user.docs.length > 0) {
//         userResult = { ...user.docs[0].data(), uid: user.docs[0].id } as UserModel
//       }
//     })
//     return userResult
//   }
//   return null
// }
