import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface HistoriesState {
  transferSuccess: boolean
}

const initialState: HistoriesState = {
  transferSuccess: false
}

export const historySlice = createSlice({
  name: 'histories',
  initialState,
  reducers: {
    refreshHistories: (state, action: PayloadAction<number>) => {
      if (action.payload) {
        state.transferSuccess = true
      } else {
        state.transferSuccess = false
      }
    }
  }
})

// Action creators are generated for each case reducer function
export const { refreshHistories } = historySlice.actions

export default historySlice.reducer
