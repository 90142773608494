import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AlertMessage } from 'config/types/common'

const initialState = {
  isOpen: false,
  isLoading: false,
  message: '',
  status: ''
}

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openModal: (state, action: PayloadAction<AlertMessage>) => {
      const { payload } = action
      state.message = payload.message
      state.status = payload.status
      state.isOpen = true
    },
    closeModal: state => {
      state.message = ''
      state.status = ''
      state.isOpen = false
    },
    openLoadingModal: (state, action: PayloadAction<string>) => {
      const { payload } = action
      state.message = payload
      state.isLoading = true
    },
    closeLoadingModal: state => {
      state.message = ''
      state.isLoading = false
    }
  }
})

export const { openModal, closeModal, openLoadingModal, closeLoadingModal } = modalSlice.actions

export default modalSlice.reducer
