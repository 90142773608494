import { lazy, ReactNode } from 'react'

const Home = lazy(() => import('pages/Home'))

interface IRouteItem {
  name: string
  element: ReactNode
  path: string
}

interface IRoutes {
  public: IRouteItem[]
  private: IRouteItem[]
}

export const ROUTES = {
  HOME: '/'
}

export const routes: IRoutes = {
  public: [
    {
      name: 'Home',
      path: ROUTES.HOME,
      element: <Home />
    }
  ],
  private: []
}
