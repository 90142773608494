export function setProvider(type: string) {
  return window.localStorage.setItem('provider', type)
}

export function getProvider() {
  return window.localStorage.getItem('provider')
}

export function setIsCheckSignInFirebase(type: string) {
  return window.localStorage.setItem('signInFirebase', type)
}

export function getIsCheckSignInFirebase() {
  return window.localStorage.getItem('signInFirebase')
}

export function removeIsCheckSignInFirebase() {
  return window.localStorage.removeItem('signInFirebase')
}

export function setRefCode(type: string) {
  return window.localStorage.setItem('ref', type)
}

export function getRefCode() {
  return window.localStorage.getItem('ref') ?? ''
}

export function removeRefCode() {
  return window.localStorage.removeItem('ref')
}
