import { doc, getDoc } from '@firebase/firestore'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { TABLE_SETTING } from 'config/constants'
import { db } from 'config/db/firebase'
import { Setting } from 'config/types/settingType'

type SettingState = {
  settings: Setting
}
const initialState: SettingState = {
  settings: {} as Setting
}

export const fetchSetting = createAsyncThunk('settingSlice/fetchSetting', async () => {
  const settingDocRef = doc(db, TABLE_SETTING, 'set')
  try {
    const setting = await getDoc(settingDocRef)
    return setting.data()
  } catch (error) {
    console.log('Error fetch setting:', error)
  }
})

export const settingSlice = createSlice({
  name: 'settingSlice',
  initialState,
  reducers: {},
  extraReducers: {
    // setting
    [fetchSetting.fulfilled.toString()]: (state, { payload }) => {
      state.settings = payload
    }
  }
})

// Action creators are generated for each case reducer function
// export const {} = settingSlice.actions

export default settingSlice.reducer
