import { Web3Provider } from '@ethersproject/providers'
import { Web3ReactProvider } from '@web3-react/core'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { getRefcodeURL } from 'utils/helpers'
import App from './App'
import { ThemeProvider as ThemeProviderContext } from './context/ThemeContext'
import './index.scss'
import store from './store'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

function getLibrary(provider) {
  const library = new Web3Provider(provider)
  library.pollingInterval = 8000
  return library
}

getRefcodeURL()

root.render(
  <Web3ReactProvider getLibrary={getLibrary}>
    <Provider store={store}>
      <BrowserRouter>
        <ThemeProviderContext>
          <App />
        </ThemeProviderContext>
      </BrowserRouter>
    </Provider>
  </Web3ReactProvider>
)
