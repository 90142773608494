import { collection, getDocs, orderBy, query, where } from '@firebase/firestore'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { STATUS_RUN_INVEST, TABLE_INVEST, TOTAL_PERCENT_WITHDRAW } from 'config/constants'
import { db } from 'config/db/firebase'
import { UInvestModel } from 'config/types/investType'

type UInvestState = {
  uInvest: UInvestModel | null
  totalAmountOfMonthInvest: number
}

const initialState: UInvestState = {
  uInvest: {} as UInvestModel | null,
  totalAmountOfMonthInvest: 0
}

export const fetchUInvest = createAsyncThunk('uInvestSlice/fetchUInvest', async (uid: string) => {
  try {
    const investDocRef = collection(db, TABLE_INVEST)
    // Only get first with condition status run
    const q = query(investDocRef, where('uUid', '==', uid), orderBy('timeCreated', 'desc'))
    const uInvest = await getDocs(q)
    if (uInvest.docs.length > 0) {
      const newUInvest: UInvestModel[] = []
      uInvest.docs.filter(doc => {
        if (doc.data()['status'] === STATUS_RUN_INVEST) {
          newUInvest.push({ ...doc.data(), id: doc.id } as UInvestModel)
        }
      })
      return newUInvest[0]
    }
  } catch (error) {
    console.log('Error fetch uInvest:', error)
  }
})

export const fetchTotalOfMonthInvest = createAsyncThunk('uInvestSlice/fetchTotalOfMonthInvest', async (uid: string) => {
  try {
    // const date = new Date()
    // const year = date.getFullYear()
    // const month = date.getMonth()
    // const startDate = new Date(year, month)
    // const endDate = new Date(year, month + 1)

    const investDocRef = collection(db, TABLE_INVEST)

    const q = query(investDocRef, where('uUid', '==', uid), orderBy('timeCreated', 'desc'))

    const investOfMonth = await getDocs(q)

    if (investOfMonth.docs.length > 0) {
      const totalAmount = investOfMonth.docs[0].data().amount
      return totalAmount * TOTAL_PERCENT_WITHDRAW
    }
  } catch (error) {
    console.log('Error fetch swap or withdraw:', error)
  }
})

export const investSlice = createSlice({
  name: 'investSlice',
  initialState,
  reducers: {},
  extraReducers: {
    // Current uInvest
    [fetchUInvest.fulfilled.toString()]: (state, { payload }) => {
      state.uInvest = payload
    },
    // Current uInvest
    [fetchTotalOfMonthInvest.fulfilled.toString()]: (state, { payload }) => {
      state.totalAmountOfMonthInvest = payload
    }
  }
})

// Action creators are generated for each case reducer function
// export const {} = investSlice.actions

export default investSlice.reducer
