import logoBNB from 'assets/img/bnb-logo.png'
import logoCDF from 'assets/img/logo.png'
import logoUSDT from 'assets/img/usdt-logo.png'
import axios from 'axios'
import { closeLoadingModal, openLoadingModal, openModal } from 'component/Modal/store/modalSlice'
import { MIN_NEED, NOT_BALANCE_ENOUGH, NOT_RIGHT, NOT_SAME_ACCOUNT, TOKEN_SYMBOL_CDF, TOKEN_SYMBOL_USDT } from 'config/constants'
import { DateDiff } from 'config/types/common'
import { UInvestModel } from 'config/types/investType'
import { updateUserDB } from 'services/firebaseFireStore'
import { store } from './../store/index'
import { removeRefCode, setRefCode } from './localStorage'

export function formatToDot(value: string, length = 4) {
  return `${value.substring(0, length + 2)}...${value.substring(value.length - length)}`
}

export const getMyIp = async () => {
  const res = await axios.get('https://geolocation-db.com/json/')
  return res.data.IPv4
}

export async function getBnbPriceApi(): Promise<number> {
  const _url = 'https://api.bscscan.com/api?module=stats&action=bnbprice&apikey=NUK56EKJ4MJDZ3AV3K934UDS425YA52FIY'
  const response: Response = await fetch(_url)
  if (response.status === 200) {
    const json = await response.json()
    return json.result.ethusd
  } else {
    console.log(`status: ${response.status}`)
    return 0
  }
}

const getSymbolByWallet = (wallet?: string) => {
  if (wallet == 'wUsd') {
    return TOKEN_SYMBOL_USDT
  } else if (wallet == 'wBnb') {
    return 'BNB'
  } else if (wallet == 'wDot') {
    return 'DOT'
  } else if (wallet == 'wPoly') {
    return 'POLYGON'
  } else if (wallet == 'wShiba') {
    return 'SHIBA'
  } else if (wallet == 'wAda') {
    return 'ADA'
  } else if (wallet == 'wToken') {
    return TOKEN_SYMBOL_CDF
  } else if (wallet == 'wCom') {
    return TOKEN_SYMBOL_USDT
  } else if (wallet == 'wProfit') {
    return TOKEN_SYMBOL_USDT
  } else {
    return ''
  }
}

export const isURL = str => {
  let url
  try {
    url = new URL(str)
  } catch (_) {
    return false
  }
  return url.protocol === 'http:' || url.protocol === 'https:'
}

export const splitRefUrl = (textInput: string) => (isURL(textInput.trim()) ? textInput.trim().split('?ref=')[1] : textInput.trim())

export const checkSponSameMe = (dk, userCurrent) => {
  if (dk) {
    return true
  } else {
    removeRefCode()
    updateUserDB(userCurrent.uid!, { sponRefCode: '' })
    return false
  }
}

export const yourselfSponCheck = (dk, userCurrent) => {
  if (dk) {
    return true
  } else {
    removeRefCode()
    updateUserDB(userCurrent.uid!, { sponRefCode: '' })
    return false
  }
}

export const minAmountCheck = (dk: boolean, min: number, w?: string) => {
  if (dk) {
    return true
  }
  return `${MIN_NEED}: ${w !== undefined ? '>=' : '>'} ${decimals(min)} ${w !== undefined ? getSymbolByWallet(w) : ''} !`
}

export const balanceCheck = (dk: boolean) => {
  if (dk) {
    return true
  }
  return NOT_BALANCE_ENOUGH
}

export const smartContactCheck = (dk: boolean) => {
  if (dk) {
    return true
  }
  return NOT_SAME_ACCOUNT
}

const decimalDigits = (num: number, isDec = false) => {
  if (isDec) {
    return num.toFixed(2)
  }
  return new Intl.NumberFormat('en-US').format(num)
}

const isInteger = value => value % 1 == 0

export const decimals = (num: number) => {
  if (num < 0) {
    const numD = num * -1
    if (isInteger(numD)) {
      return `-${decimalDigits(numD)}`
    } else {
      return `-${decimalDigits(numD, true)}`
    }
  } else {
    if (num >= 1) {
      if (isInteger(num)) {
        return decimalDigits(num)
      } else {
        return decimalDigits(num, true)
      }
    } else {
      return num.toPrecision(2)
    }
  }
}

export const xAmount = (amount: number) => {
  return amount
  if (amount >= 1000 && amount < 5000) {
    return amount * 2
  } else if (amount >= 5000 && amount < 20000) {
    return amount * 3
  } else if (amount >= 20000 && amount < 100000) {
    return amount * 4
  } else if (amount >= 100000) {
    return amount * 5
  } else {
    return amount
  }
}

export const toHex = num => {
  const val = Number(num)
  return '0x' + val.toString(16)
}

export function getRefcodeURL() {
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const ref = urlParams.get('ref')
  if (ref && ref.length > 6) {
    setRefCode(ref)
    return ref
  }
  return ''
}

export function alertError(message: string, status = 'error') {
  store.dispatch(openModal({ message: message, status }))
}

export function alertInfo(message: string, status = 'info') {
  store.dispatch(openModal({ message: message, status }))
}

export function alertSuccess(message: string, status = 'success') {
  store.dispatch(openModal({ message: message, status }))
}

export function loading(message: string) {
  store.dispatch(openLoadingModal(message))
}

export function hideLoading() {
  store.dispatch(closeLoadingModal())
}

export const useLogo = (symbol: string): string => {
  let logo = logoCDF
  if (symbol === 'BNB') {
    logo = logoBNB
  }
  if (symbol === 'USDT') {
    logo = logoUSDT
  }
  return logo
}

function dateDiff(str1, str2): DateDiff {
  const diff = Date.parse(str2) - Date.parse(str1)
  return {
    diff: diff,
    ms: Math.floor(diff % 1000),
    s: Math.floor((diff / 1000) % 60),
    m: Math.floor((diff / 60000) % 60),
    h: Math.floor((diff / 3600000) % 24),
    d: Math.floor(diff / 86400000)
  }
}

export const getStopPercent = (dataInvest: UInvestModel | null) => {
  if (!dataInvest) {
    alertError(`${NOT_RIGHT}: invest data null`)
    return
  }

  const time = new Date(dataInvest.timeCreated.seconds * 1000).toJSON().slice(0, 10)
  const now = new Date().toJSON().slice(0, 10)

  const pass = dateDiff(time, now).d

  if (pass < 360) {
    return false
  }
  return 'Do you want stop invest?'
  // if (pass < 30) {
  //   return 'Stop invest fee = 15%'
  // } else if (pass >= 30 && pass < 60) {
  //   return 'Stop invest fee = 5%'
  // } else {
  //   return false
  // }
}

export const toUsd = (amount: number, fee: number, rate: number) => {
  const amountPositive = amount * -1
  const totalAmount = amountPositive - amountPositive * fee
  return totalAmount * rate
}
