/* eslint-disable @typescript-eslint/no-explicit-any */
import { combineReducers, configureStore } from '@reduxjs/toolkit'
import modalReducer from 'component/Modal/store/modalSlice'
import historiesSReducer from 'pages/Home/component/DepositHistory/store/historySlice'
import investReducer from 'store/invest/investSlice'
import transactionReducer from 'store/transaction/transactionSlice'
import settingReducer from 'store/setting/settingSlice'
import userReducer from 'store/user/userSlice'

const appReducer = combineReducers({
  historiesSReducer,
  userReducer,
  settingReducer,
  investReducer,
  transactionReducer,
  modalReducer
})

const rootReducer = (state: any, action: any) => appReducer(state, action)

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false
    })
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export default store
