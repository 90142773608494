// export const ADDRESS_CONTRACT_USDT = '0xeF2688354bCd43ced0E6fe4C592E566993d61Ef7' // contract USDT Testnet
export const ADDRESS_CONTRACT_USDT = '0x55d398326f99059fF775485246999027B3197955' // contract USDT Mainnet
export const TOKEN_SYMBOL_USDT = 'USDT'

// Token BNB
export const TOKEN_SYMBOL_BNB = 'BNB'
// export const TOKEN_CHAIN_ID_BNB = 97 // BSC Testnet
export const TOKEN_CHAIN_ID_BNB = 56 // BSC Mainnet

// Token FIE
// export const ADDRESS_CONTRACT_CDF = '0x337610d27c682E347C9cD60BD4b3b107C9d34dDd' // contract CDF Testnet
export const ADDRESS_CONTRACT_CDF = '0xecD499FC7bA246825BD043F75aAf4a30bF27b12F' // contract FIE Mainnet
// export const ADDRESS_CONTRACT_CDF = '0xd55cF7b055A5E3Eb872DD2dE3e0900D06f8F8B61' // tesstnet

export const TOKEN_NAME_CDF = 'FinEdu'
// export const TOKEN_SYMBOL_CDF = 'USDT' // symbol USDT Testnet
export const TOKEN_SYMBOL_CDF = 'FIE' // symbol FIE Mainnet
export const TOKEN_DECIMALS_CDF = 18
export const TOKEN_IMAGE_CDF = 'https://invest.finedu.jp/tklogo.png'

// Wallet Master
// export const WALLET_MASTER = '0x01320450d61eFfede1B04CE306917f905300700e' // Test
export const WALLET_MASTER = '0xa9F93E4B0ce970fCb7176752959E1DB4b1547d6f' // Master

// Table
export const TABLE_INVEST = 'invests'
export const TABLE_DEPOSIT = 'deposits'
export const TABLE_TRANSACTION = 't'
export const TABLE_USER = 'users'
export const TABLE_IP = 'ips'
export const TABLE_SETTING = 'settings'
export const TABLE_PROFIT = 'profits'
export const TABLE_COMMISSION = 'commissions'

// Field Table
export const FIELD_VOLUMEME_TABLE_USER = 'volumeMe'

// Constant
export const RATE_USDT = 1
export const ONE_DMILIS = 86400000
export const MIN_DEPOSIT = 0
export const STATUS_FAIL = 0
export const RATE_DAY = 0.5
export const STATUS_RUN_INVEST = 'run'
export const TYPE_SWAP = 'Swap'
export const TYPE_WITHDRAW = 'Withdraw'
export const TOTAL_PERCENT_WITHDRAW = 0.045

// Message
export const SYMBOL_ALL = '$'
export const MIN_NEED = 'The minimum amount needed is'
export const NOT_BALANCE_ENOUGH = 'Balance wallet is not enough'
export const NOT_RIGHT = 'Something Wrong'
export const SUCCESS = 'Successfully'
export const NOT_SAME_ACCOUNT = 'Please do not send the same account'
export const INVEST_CREATE_DONE = 'Invest created successfully'
export const NOT_CONNECT = 'Please connect your wallet'
export const INPUT_EMPTY = 'Please enter input value'
export const SPONSOR_NOT_FOUND = 'Sponsor user is not found!'
export const COULD_NOT_STOP_INVEST = 'Could not stop invest before 360 days.'
