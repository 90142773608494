import { doc, getDoc } from '@firebase/firestore'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { TABLE_USER } from 'config/constants'
import { db } from 'config/db/firebase'
import { UserModel } from 'config/types/userType'

type UserState = {
  currentUser: UserModel
}

const initialState: UserState = {
  currentUser: {} as UserModel
}

export const fetchCurrentUser = createAsyncThunk('userSlice/fetchCurrentUser', async (uid: string) => {
  const userDocRef = doc(db, TABLE_USER, uid)
  try {
    const user = await getDoc(userDocRef)
    return { ...user.data(), uid: uid }
  } catch (error) {
    console.log('Error fetch current user:', error)
  }
})

export const userSlice = createSlice({
  name: 'userSlice',
  initialState,
  reducers: {},
  extraReducers: {
    // Current user
    [fetchCurrentUser.fulfilled.toString()]: (state, { payload }) => {
      state.currentUser = payload
    }
  }
})

// Action creators are generated for each case reducer function
// export const {} = userSlice.actions

export default userSlice.reducer
